import React from "react"

import CarouselControl from "./CarouselControl"

const Carousel = React.forwardRef(
  (
    {
      paginate,
      direction,
      children,
      paginateComponent,
      fullscreen,
      carouselBodyBox,
    },
    ref
  ) => {
    const handleClick = direction => {
      paginate(direction)
    }

    return (
      <React.Fragment>
        <main
          className="overflow-hidden"
          ref={ref}
          style={{
            justifyContent: "center",
            transition: "background 0.5s ease 0s",
            width: "100%",
            display: "inherit",
            position: fullscreen ? "absolute" : "unset",
            top: fullscreen ? "50%" : null,
            transform: fullscreen ? "translateY(-50%)" : null,
          }}
          custom={direction}
        >
          {children}
          <CarouselControl
            className={`${fullscreen ? "pageinateFull prev" : "prev"}`}
            style={
              carouselBodyBox
                ? { height: `${carouselBodyBox.height}px` }
                : { height: "50vh" }
            }
            handleClick={() => handleClick(-1)}
            component={paginateComponent ? paginateComponent : null}
          />
          <CarouselControl
            className={`${fullscreen ? "pageinateFull next" : "next"}`}
            style={
              carouselBodyBox
                ? { height: `${carouselBodyBox.height}px` }
                : { height: "50vh" }
            }
            handleClick={() => handleClick(1)}
            component={paginateComponent ? paginateComponent : null}
          />
        </main>
      </React.Fragment>
    )
  }
)

export default Carousel
