import React from "react"
import { Link, navigate } from "gatsby"

const ProjectHeader = ({
  backTo,
  pageName,
  handleProjectFullscreen,
  fullscreen,
  siteName,
}) => (
  <header
    className="text-brand-black uppercase mx-auto my-0 w-full flex items-center font-semibold tracking-widest text-xs lg:text-sm"
    style={{
      position: fullscreen ? "absolute" : "relative",
      zIndex: fullscreen ? 3 : 5,
      WebkitFilter: fullscreen ? "invert(100%)" : null,
      filter: fullscreen ? "invert(100%)" : null,
    }}
  >
    <div className="w-1/6 md:w-2/6">
      <div
        onClick={() => navigate(backTo)}
        style={{ cursor: "pointer" }}
        className="p-5 md:p-8 lg:p-10 3xl:p-24 flex items-center hover:opacity-50 duration-300 ease-in-out"
      >
        <svg
          className="h-3 w-3 mr-1"
          fill="currentColor"
          viewBox="0 0 8 12"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M7.41 1.41L6 0L0 6L6 12L7.41 10.59L2.83 6L7.41 1.41Z" />
        </svg>
        <span className="hidden md:inline-block">{pageName}</span>
      </div>
    </div>

    <div className="w-4/6 md:w-2/6 text-center">
      <Link
        to="/"
        className={`${
          fullscreen ? `opacity-0` : `opacity-100`
        } w-4/6 p-5 md:p-8 lg:p-10 3xl:p-24 transition-opacity duration-500 ease-in-out
        hover:opacity-50`}
      >
        {siteName}
      </Link>
    </div>

    <div className="w-1/6 md:w-2/6 text-right">
      <button
        className="p-5 md:p-8 lg:p-10 3xl:p-24 hover:opacity-50 duration-300 ease-in-out"
        onClick={() => handleProjectFullscreen()}
      >
        {fullscreen ? (
          <svg
            className="h-4 w-4"
            viewBox="0 0 14 14"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              fill="currentColor"
              clipRule="evenodd"
              d="M0 3H3V0H5V5H0V3ZM3 11H0V9H5V14H3V11ZM9 14H11V11H14V9H9V14ZM11 0V3H14V5H9V0H11Z"
            />
          </svg>
        ) : (
          <svg
            className="h-4 w-4"
            viewBox="0 0 14 14"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              fill="currentColor"
              clipRule="evenodd"
              d="M2 5H0V0H5V2H2V5ZM0 9H2V12H5V14H0V9ZM12 12H9V14H14V9H12V12ZM9 2V0H14V5H12V2H9Z"
            />
          </svg>
        )}
      </button>
    </div>
  </header>
)

export default ProjectHeader
