import React from "react"
import { motion } from "framer-motion"
import Img from "gatsby-image"

const duration = 0.5

const variants = {
  enter: direction => {
    // console.log("enterDirection", direction)
    return {
      x: direction > 0 ? 1000 : -1000,
      opacity: 0,
      transition: {
        duration: duration,
        delay: 0,
        when: "afterParent",
      },
    }
  },
  center: {
    x: 0,
    opacity: 1,
    transition: {
      duration: duration,
      delay: 0,
      when: "beforeChildren",
    },
  },
  exit: direction => {
    // console.log("exitDirection", direction)
    return {
      x: direction < 0 ? 1000 : -1000,
      opacity: 0,
      transition: {
        duration: duration,
        when: "afterChildren",
        delay: duration,
      },
    }
  },
}

/**
 * Experimenting with distilling swipe offset and velocity into a single variable, so the
 * less distance a user has swiped, the more velocity they need to register as a swipe.
 * Should accomodate longer swipes and short flicks without having binary checks on
 * just distance thresholds and velocity > 0.
 */

const swipeConfidenceThreshold = 1000
const swipePower = (offset, velocity) => {
  return Math.abs(offset) * velocity
}

const CarouselItem = ({ src, page, direction, fullscreen, paginate }) => {
  console.log(src)
  if (src) {
    if (src.extension) {
      if (src.extension !== "video") {
        let fluidImage
        console.log(src)
        if (src.childImageSharp) {
          if (src.childImageSharp.fluid) {
            fluidImage = src.childImageSharp.fluid
          } else {
            fluidImage = src.publicURL
          }
        }

        return (
          <motion.div
            id="media-container"
            key={page}
            custom={direction}
            variants={variants}
            initial={false}
            animate="center"
            // className={"fixHeight"}
            style={
              fullscreen
                ? { width: "100vw", transition: "all 0.5s ease" }
                : { width: "100vw", transition: "all 0.5s ease" }
            }
            className={`${fullscreen ? `md:h-screen` : `h-auto`} flex-auto`}
            exit="exit"
            transition={{
              x: { duration },
              opacity: { duration: 0.2 },
            }}
            drag="x"
            dragConstraints={{ left: 0, right: 0 }}
            dragElastic={1}
            onDragEnd={(e, { offset, velocity }) => {
              const swipe = swipePower(offset.x, velocity.x)

              if (swipe < -swipeConfidenceThreshold) {
                paginate(1)
              } else if (swipe > swipeConfidenceThreshold) {
                paginate(-1)
              }
            }}
          >
            {typeof fluidImage != String ? (
              <Img
                alt={src.name}
                key={src.name}
                fluid={fluidImage}
                className={
                  fullscreen ? "gatsby-image-wrapper-fullScreenFix" : null
                }
                style={{ maxHeight: "100%" }}
                imgStyle={{ objectFit: "contain" }}
              />
            ) : (
              <div
                key={src.name}
                className={
                  fullscreen ? "gatsby-image-wrapper-fullScreenFix" : null
                }
                style={{ maxHeight: "100%" }}
              >
                <img
                  alt={src.name}
                  src={fluidImage}
                  style={{ objectFit: "contain" }}
                />
              </div>
            )}
          </motion.div>
        )
      } else {
        return (
          <motion.div
            key={page}
            custom={direction}
            variants={variants}
            initial={false}
            animate="center"
            className={"fixWidth"}
            exit="exit"
            transition={{
              x: { duration },
              opacity: { duration: 0.2 },
            }}
            style={{ zIndex: 10 }}
            drag="x"
            dragConstraints={{ left: 0, right: 0 }}
            dragElastic={1}
            onDragEnd={(e, { offset, velocity }) => {
              const swipe = swipePower(offset.x, velocity.x)

              if (swipe < -swipeConfidenceThreshold) {
                paginate(1)
              } else if (swipe > swipeConfidenceThreshold) {
                paginate(-1)
              }
            }}
          >
            {console.log(src.poster)}
            <div className={"video-container"}>
              <video
                className={"video"}
                style={{
                  width: fullscreen ? "100vw" : "inherit",
                  transition: "all 5sec ease",
                  objectFit: "fit",
                }}
                preload={"metadata"}
                poster={src.poster.publicURL}
                autoPlay={true}
                controls
                controlsList="nodownload"
                src={src.videoUrl}
              ></video>
            </div>
          </motion.div>
        )
      }
    } else {
      return (
        <>
          <h4>Loading problem ...</h4>
        </>
      )
    }
  }
}

export default CarouselItem
