import React from "react"
import { graphql } from "gatsby"
import Project from "./Project"

export const query = graphql`
  query ProjectTemplate($slug: String!, $pathToFiles: String!) {
    project: markdownRemark(fields: { slug: { eq: $slug } }) {
      frontmatter {
        title
        client
        date(formatString: "dddd DD MMMM YYYY", locale: "fr-FR")
        type
        videos {
          url
          poster {
            name
            publicURL
            colors {
              vibrant
            }
            childImageSharp {
              fluid(quality: 90, maxHeight: 1200) {
                srcSetWebp
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        published
        poster {
          name
          publicURL
          colors {
            vibrant
          }
          childImageSharp {
            fluid(quality: 90, maxHeight: 1200) {
              srcSetWebp
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
      excerpt(pruneLength: 200)
      html
    }
    images: allFile(
      filter: {
        relativePath: { regex: $pathToFiles }
        extension: { regex: "/jpeg|jpg|png|gif/" }
      }
      sort: { fields: name, order: ASC }
    ) {
      nodes {
        name
        extension
        publicURL
        childImageSharp {
          fluid(quality: 90, maxHeight: 1200) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
    site: site {
      siteMetadata {
        title
      }
    }
  }
`

const ProjectPage = props => {
  return <Project {...props} />
}
export default ProjectPage
