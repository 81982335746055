const makeVideoObject = (videos, poster) => {
  if (videos) {
    if (typeof videos === Array) {
      let temp = []
      for (var i = 0; i < videos.length; i++) {
        let src = {}
        src.videoUrl = videos[i].url
        src.poster = poster[i]
        src.name = "placeholder"
        src.extension = "video"
        temp.push(...src)
      }
      return temp
    } else if (typeof videos === "object") {
      console.log(videos, typeof videos)
      let temp = []
      for (var i = 0; i < videos.length; i++) {
        let src = {}
        src.videoUrl = videos[i].url
        src.poster = videos[i].poster
        src.name = videos[i].poster.name
        src.extension = "video"
        console.log(src)
        temp.push(src)
      }
      return temp
    } else {
      return [
        {
          videoUrl: videos,
          poster: poster,
          name: "placeholder",
          extension: "video",
        },
      ]
    }
  } else {
    return [
      {
        videoUrl: videos,
        poster: poster,
        name: "placeholder",
        extension: "video",
      },
    ]
  }
}

export default makeVideoObject
