import * as React from "react"

const CarouselControl = ({ className, component, style, handleClick }) => {
  return (
    <div
      className={className ? className : null}
      style={style ? style : null}
      onClick={handleClick}
    >
      {component ? component : null}
    </div>
  )
}

export default CarouselControl
