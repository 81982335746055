import React from "react"
import { useState, useEffect } from "react"
import { navigate } from "@reach/router"
import { withPrefix } from "gatsby"
import { wrap } from "popmotion"
import { useQueryParam, StringParam, NumberParam } from "use-query-params"
import SEO from "../components/Seo"
import { useBbox } from "../utils/useBbox"
import useKeyPress from "../utils/useKeyPress"
import useWindowDimensions from "../utils/useWindowDimensions"
import Carousel from "./carousel/Carousel"
import ProjectHeader from "./ProjectHeader"
import CarouselItem from "./carousel/CarouselItem"
import makeVideoObject from "./makeVideoObject"

const Project = ({ location, data, bgColor }) => {
  const { project, images, site } = data
  const { frontmatter, excerpt } = project
  const { title, client, videos, type } = frontmatter
  // eslint-disable-next-line
  const [category, setCategory] = useQueryParam("cat", StringParam)
  // eslint-disable-next-line
  const [projectId, setProjectId] = useQueryParam("pro", NumberParam)
  const [nextPage, setNextPage] = useState(true)
  const [carouselBodyBox, carouselBodyRef] = useBbox(nextPage)
  const [assets, setAssets] = useState(() =>
    videos ? [videos] : [...images.nodes]
  )
  const [backTo, setBackTo] = useState("/")
  const [pageName, setPageName] = useState("/")
  const windowDimensions = useWindowDimensions()
  useEffect(() => {
    if (nextPage) {
      setNextPage(false)
    }
  }, [nextPage, carouselBodyRef])

  // eslint-disable-next-line
  useEffect(() => {
    let videosTogether = []
    // eslint-disable-next-line
    const allButPoster = images.nodes.filter(node => {
      if (node.extension !== "md") {
        // console.log(node);
        // if (!video) {
        return node
        // }
      }
    })
    let videosArray
    // console.log(video);
    if (assets) {
      if (type === "video") {
        if (videos) {
          videosArray = makeVideoObject(videos)

          // console.log("result from videos:",videos);
        }
        if (videosArray !== null && videos && videos !== undefined) {
          if (allButPoster.length > 0) {
            // console.log("we set ", allButPoster);
            setAssets([...videosArray, ...allButPoster])
          } else {
            // console.log("we set ", allButPoster,images.nodes);
            setAssets([...videosArray])
          }
        }
      } else {
        setAssets([...allButPoster])
      }
    }
    // eslint-disable-next-line
  }, [])

  // Setting the "Back To" button
  useEffect(() => {
    let thisBackTo = "/"
    if (location.href.split("?")) {
      let temp = location.href.split("?")[1].split("=")
      let projectNumber = location.href.split("?")[1].split("=")[2]
      let projectCat = location.href.split("?")[1].split("=")[1].split("&")[0]
      if (projectCat) {
        // console.log(projectCat);
        thisBackTo = `/${projectCat}/#${projectNumber}`
      } else {
        thisBackTo = `/#${projectNumber}`
      }

      setPageName(projectCat)
    }
    setBackTo(thisBackTo)
  }, [backTo, location.state])

  // Navigating the Carousel Slides

  const keyboardInputNext = useKeyPress("ArrowRight")
  const keyboardInputPrev = useKeyPress("ArrowLeft")
  const keyboardInputClose = useKeyPress("Escape")
  const [[carouselPage, direction], setPage] = useState([0, 0])
  const assetIndex = wrap(0, assets.length, carouselPage)

  const paginate = newDirection => {
    setNextPage(true)
    if (Math.abs(carouselPage) < assets.length) {
      // console.log("navigate back",backTo,location.href,projectId,category);
      if (carouselPage === 0 && newDirection === -1) {
        navigate(withPrefix(backTo))
      } else {
        setPage([carouselPage + newDirection, newDirection])
      }
    }

    if (Math.abs(carouselPage) === assets.length && newDirection === -1) {
      setPage([carouselPage + newDirection, newDirection])
    }

    if (Math.abs(carouselPage) >= assets.length - 1 && newDirection === 1) {
      navigate(withPrefix(backTo))
    }
  }

  // Fullscreen mode

  const [fullscreen, setFullscreen] = useState(false)
  useEffect(() => {
    if (keyboardInputNext) {
      paginate(1)
    }
    if (keyboardInputPrev) {
      paginate(-1)
    }
    if (keyboardInputClose) {
      if (fullscreen) {
        setFullscreen(false)
      }
      if (!fullscreen) {
        navigate(withPrefix(backTo))
      }
    }
  }, [
    keyboardInputNext,
    keyboardInputPrev,
    keyboardInputClose,
    fullscreen,
    backTo,
  ])

  const handleProjectFullscreen = e => {
    if (fullscreen) {
      setFullscreen(false)
      setNextPage(true)
    } else {
      setFullscreen(true)
      setNextPage(true)
    }
  }

  let frontPoster = ""
  if (frontmatter.poster) {
    frontPoster = frontmatter.poster.childImageSharp.fluid.src
  }
  if (frontmatter.videos) {
    if (frontmatter.videos.length >= 1) {
      frontPoster = frontmatter.videos[0].poster.childImageSharp.fluid.src
    }
  }

  return (
    <React.Fragment>
      <SEO title={title} description={excerpt} image={frontPoster} />{" "}
      <div
        id="project-page-wrapper"
        style={{
          position: "fixed",
          width: `${windowDimensions.width}px`,
          height: `${windowDimensions.height}px`,
          minHeight: "100vh",
          // eslint-disable-next-line
          minHeight: "-webkit-fill-available",
          display: "flex",
          flexDirection: "column",
          overflow: "hidden",
          backgroundColor: fullscreen ? "#000000" : null,
          transition: "all .5 ease",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <ProjectHeader
          backTo={backTo}
          pageName={pageName}
          handleProjectFullscreen={handleProjectFullscreen}
          fullscreen={fullscreen}
          siteName={site.siteMetadata.title}
        />
        <Carousel
          ref={carouselBodyRef}
          carouselBodyBox={carouselBodyBox}
          page={carouselPage}
          setPage={setPage}
          direction={direction}
          paginate={paginate}
          fullscreen={fullscreen}
        >
          <CarouselItem
            fullscreen={fullscreen}
            src={assets[assetIndex]}
            paginate={paginate}
            page={carouselPage}
            direction={direction}
          />
        </Carousel>

        <footer
          style={{
            position: fullscreen ? "absolute" : "relative",
            width: "100%",
            bottom: 0,
            zIndex: fullscreen ? 3 : null,
            WebkitFilter: fullscreen ? "invert(100%)" : null,
            filter: fullscreen ? "invert(100%)" : null,
            pointerEvents: "none",
          }}
          className="w-screen flex p-5 md:p-8 lg:p-10 3xl:p-24 bottom-0 items-center justify-center font-semibold lg:text-sm text-xs"
        >
          {client ? (
            <p>{`${client} ${title} ${carouselPage + 1}/${assets.length}`}</p>
          ) : (
            <p>{`${title} ${carouselPage + 1}/${assets.length}`}</p>
          )}
        </footer>
      </div>
    </React.Fragment>
  )
}

export default Project
